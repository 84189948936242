<template>
<div class="row mt-5">
    <div class="col-12">
        <div class="card">
            <div class="card-body position-relative">
                <img class="svg dark img-circle" src="/images/ecotechwhite.png" alt="svg" >
                <div class="row mt-5">
                    <div class="col-12">
                        <div class="ap-nameAddress pb-3">
                            <h6 class="ap-nameAddress__title text-center">{{listData.PLANTNAME?listData.PLANTNAME:'-'}}</h6>
                            <div class="d-flex justify-content-center mt-2">
                              <div class="userDatatable-content d-inline-block">
                                  <span class="bg-opacity-success  color-success rounded-pill userDatatable-content-status active">active</span>
                              </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <p class="title-p">
                      <span class="icon-title">
                        <svg  viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.5716 11.1426V9.99979C10.5716 9.39358 10.3308 8.8122 9.9021 8.38355C9.47344 7.95489 8.89206 7.71408 8.28585 7.71408H3.71443C3.10822 7.71408 2.52684 7.95489 2.09818 8.38355C1.66953 8.8122 1.42871 9.39358 1.42871 9.99979V11.1426M8.28585 3.14265C8.28585 4.40501 7.2625 5.42836 6.00014 5.42836C4.73777 5.42836 3.71443 4.40501 3.71443 3.14265C3.71443 1.88028 4.73777 0.856934 6.00014 0.856934C7.2625 0.856934 8.28585 1.88028 8.28585 3.14265Z" stroke="#929AB7" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                      <span class="title-name">
                        {{GET_plantId}}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <p class="title-p">
                      <span class="icon-title">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 14" fill="none">
                          <path d="M11.1426 5.85721C11.1426 9.85721 5.99979 13.2858 5.99979 13.2858C5.99979 13.2858 0.856934 9.85721 0.856934 5.85721C0.856934 4.49324 1.39877 3.18514 2.36324 2.22066C3.32771 1.25619 4.63582 0.714355 5.99979 0.714355C7.36376 0.714355 8.67187 1.25619 9.63634 2.22066C10.6008 3.18514 11.1426 4.49324 11.1426 5.85721Z" stroke="#929AB7" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M5.99979 7.5715C6.94656 7.5715 7.71408 6.80399 7.71408 5.85721C7.71408 4.91044 6.94656 4.14293 5.99979 4.14293C5.05302 4.14293 4.2855 4.91044 4.2855 5.85721C4.2855 6.80399 5.05302 7.5715 5.99979 7.5715Z" stroke="#929AB7" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                      <span class="title-name">
                        {{listData.LOCATION?listData.LOCATION:'-'}}
                      </span>
                    </p>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-12">
                    <p class="title-p">
                      <span class="icon-title">
                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.28575 1.28564V3.57136M3.71432 1.28564V3.57136M0.857178 5.85707H11.1429M2.00003 2.4285H10C10.6312 2.4285 11.1429 2.94018 11.1429 3.57136V11.5714C11.1429 12.2025 10.6312 12.7142 10 12.7142H2.00003C1.36885 12.7142 0.857178 12.2025 0.857178 11.5714V3.57136C0.857178 2.94018 1.36885 2.4285 2.00003 2.4285Z" stroke="#929AB7" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                      <span class="title-name">
                        {{companyDetailData.date}}
                      </span>
                    </p>
                  </div>
                </div> -->
                <div class="row">
                  <div class="col-12">
                    <p class="title-p">
                      <span class="icon-title">
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.2858 1.42701V4.85558M13.2858 4.85558H9.85721M13.2858 4.85558L10.6344 2.36415C10.0202 1.7497 9.26043 1.30084 8.42589 1.05945C7.59136 0.818062 6.70927 0.792011 5.86195 0.983729C5.01462 1.17545 4.22967 1.57869 3.58034 2.15582C2.931 2.73295 2.43845 3.46517 2.14864 4.28415M0.714355 10.5699V7.1413M0.714355 7.1413H4.14293M0.714355 7.1413L3.36578 9.63273C3.97993 10.2472 4.73971 10.696 5.57425 10.9374C6.40878 11.1788 7.29087 11.2049 8.13819 11.0131C8.98552 10.8214 9.77047 10.4182 10.4198 9.84106C11.0691 9.26392 11.5617 8.5317 11.8515 7.71272" stroke="#929AB7" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                      </span>
                      <span class="title-name">
                        {{listData.UPD?listData.UPD:'-'}}
                      </span>
                    </p>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import * as serviceAPI  from '@/services/API.service';
import * as serviceMain  from '@/services/main.service';
export default {
  name: 'CompanyDetail',
  data: function () {
    return {
      listData:'',
      // companyDetailData:{
      //     companyName:'companyName',
      //     id:'2255250',
      //     address:'55 Moo 8 , Phutthamonthon Sai 4 Ampor Samphran ,Nakhon Pathom...',
      //     date:'17-05-2021 (ยังไม่ได้เติม API รอ proc)',
      //     update:'20-05-2021 13:25'
      // },    
    }
  },
  mounted() {
    this.getlistData()
  },
  methods: {
    async getlistData(){

      try {
        let data = {
          INVERTER_ID: this.GET_inverterId,
          SIDE_ID:this.GET_plantId
        }
        let getAPI = await serviceAPI.call_API('get','main/GetMainDashboard',data,'auth');
        this.listData = getAPI.data.data[0]
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }
  },
  computed: {
    getLocal:function(){
      return this.$store.getters['display/getLocal']
    },
    GET_plantId:function(){
      return this.$store.getters['localstorage/GET_plantId']
    },
    GET_inverterId:function(){
      return this.$store.getters['localstorage/GET_inverterId']
    },
    GET_socketSetReload:function(){
      return this.$store.getters['service/GET_socketSetReload']
    }
  },
  watch: {
    GET_plantId: function (val) {
      this.getlistData()
    },
    GET_socketSetReload: function (val) {
        this.getlistData()
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.img-circle{
  box-shadow: 0px 0px 8px rgb(0 0 0 / 16%);
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  min-width: 80px;
  min-height: 80px;
  max-width: 120px;
  max-height: 120px;
  position: absolute;
  top:0px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.icon-title{
  margin-right: 15px;
  svg{
    height: 0.8rem;
    margin-bottom: 3px;
  }

  
}
.title-name{
  color: #5A5F7D;
  font-size: 0.8rem;
}
.title-p{
  margin-bottom:0px ;
}
</style>
