<template>
<div class="row card">
    <div class="col-12 p-3 ">
      <div class="d-flex plant-card justify-content-start">
        <div>
          <img class="img-plant p-0 m-0" :src="image" alt="img-plant" >
        </div>
        <div class="text-plant">
          <p class="text-val">{{val}} {{unit}}</p>
          <p class="text-name">{{name}}</p>
        </div>
      </div>
    </div>
</div>

</template> 

<script>
export default {
  name: 'CardIcon',
  props: [
    "name",
    "val",
    "unit",
    "image"
  ],
}
</script>
<style scoped>
p{
    margin-left: 15px;
    margin-bottom: 0px;
}
.text-val{
  font-weight: 600;
  font-size: 1.5rem;
}
.card{
  margin-top:15px ;
}
</style>
